<template>
    <section class="nccr">
        <Nav
            class="mt-5 mb-4"
            route-name="StrategicProjects"
            parent-page-name="Стратегические проекты"
            current-page-name="НЦКР"
        />

        <div class="nccr__main-img d-none d-xl-flex">
            <div class="nccr__blurred-img mt-3 mt-lg-auto ms-lg-auto d-flex align-items-lg-end">
                <div class="research-center__title mt-lg-auto ms-lg-5 mb-lg-5">
                    <div>
                        <a
                            class="research-center__link"
                            target="_blank"
                            href="https://actcognitive.org"
                        >
                            Перейти на сайт
                        </a>
                    </div>
                    {{ getNccrPageInfo('title') }}
                </div>
                <img
                    src="../../assets/page-logo.svg"
                    class="research-center__logo ms-auto mb-5 me-5 d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <div class="research-center__title research-center__title--dark">
                <div>
                    <a
                        class="research-center__link"
                        target="_blank"
                        href="https://actcognitive.org"
                    >
                        Перейти на сайт
                    </a>
                </div>
                {{ getNccrPageInfo('title') }}
            </div>
        </div>

        <div class="nccr-about d-flex flex-column flex-lg-row align-items-center my-lg-5 py-5">
            <div class="col-12 col-lg-8 col-xl-6 d-flex justify-content-center">
                <div class="nccr-about__img-wrapper d-flex justify-content-center">
                    <img
                        class="nccr-about__img"
                        src="../../assets/views/nccr/nccr.svg"
                        alt=""
                    >
                </div>
            </div>

            <div class="col-12 col-lg-4 col-xl-6 d-flex flex-column align-items-center align-items-lg-start mt-4 mt-lg-0">
                <h3 class="nccr-about__title mt-2">{{ getNccrPageInfo('subtitle_1') }}</h3>
                <div class="nccr-about__text me-5 mt-3 mx-4 mx-lg-0">
                    {{ getNccrPageInfo('subtext_1') }}
                </div>
            </div>
        </div>

        <div class="d-flex flex-column flex-lg-row nccr-partners mt-5 mt-lg-0">
            <div class="col-12 col-lg-6">
                <h3 class="nccr-partners__title">{{ getNccrPageInfo('subtitle_2') }}</h3>
            </div>
            <p class="nccr-partners__text col-12 col-lg-6">
                {{ getNccrPageInfo('subtext_2') }}
            </p>
        </div>

        <div class="nccr-partners-icons d-flex flex-wrap justify-content-evenly mt-4">
            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2">
                <img class="nccr-partners__img" src="../../assets/views/nccr/mtc.svg" alt="">
            </div>

            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2">
                <img class="nccr-partners__img" src="../../assets/views/nccr/gazprom.svg" alt="">
            </div>

            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2">
                <img class="nccr-partners__img" src="../../assets/views/nccr/sber.svg" alt="">
            </div>

            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2">
                <img class="nccr-partners__img" src="../../assets/views/nccr/siemens.svg" alt="">
            </div>

            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2">
                <img class="nccr-partners__img" src="../../assets/views/nccr/mail.svg" alt="">
            </div>

            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2">
                <img class="nccr-partners__img" src="../../assets/views/nccr/er-telecom.svg" alt="">
            </div>

            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2">
                <img class="nccr-partners__img" src="../../assets/views/nccr/crt.png" alt="" width="272">
            </div>

            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2">
                <img class="nccr-partners__img" src="../../assets/views/nccr/almazov.svg" alt="">
            </div>
        </div>

<!--        <div class="nccr-partners-icons d-flex justify-content-between mt-4 mb-5">-->
<!--            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2 me-4 my-2">-->
<!--                <img class="nccr-partners__img" src="../../assets/views/nccr/mail.svg" alt="">-->
<!--            </div>-->

<!--            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2 me-4 my-2">-->
<!--                <img class="nccr-partners__img" src="../../assets/views/nccr/er-telecom.svg" alt="">-->
<!--            </div>-->

<!--            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2 me-4 my-2">-->
<!--                <img class="nccr-partners__img" src="../../assets/views/nccr/crt.png" alt="" width="272" height="76">-->
<!--            </div>-->

<!--            <div class="nccr-partners__img-wrapper px-4 d-flex justify-content-center align-items-center py-2 w-100 m-2">-->
<!--                <img class="nccr-partners__img" src="../../assets/views/nccr/almazov.png" alt="" width="207" height="62">-->
<!--            </div>-->
<!--        </div>-->

        <div class="d-flex flex-column flex-lg-row align-items-center research-center__task mt-5 py-5">
            <div class="col-12 col-lg-8 col-xl-6 d-flex justify-content-center justify-content-lg-start">
                <div class="task-img-card">
                    <img
                        class="task__img"
                        src="../../assets/views/nccr/nccr-task.png"
                        alt=""
                    >
                </div>
            </div>

            <div
                class="col-12 col-lg-4 col-xl-6 d-flex flex-column align-items-center align-items-lg-start mt-4 mt-lg-0">
                <h3 class="task__title">{{ getNccrPageInfo('subtitle_3') }}</h3>
                <div class="task__text mt-3">
                    {{ getNccrPageInfo('subtext_3') }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import {computed} from "vue";
import { useStore } from "vuex";

export default {
    name: "NCCR",

    components: {
        Nav
    },

    setup() {
        const store = useStore()
        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('pages/getNccrPage')
        const nccrPage = computed(() => store.getters['pages/nccrPage'])

        const getNccrPageInfo = (key) => {
            if (nccrPage.value) {
                return nccrPage.value[key]
            }

            return ''
        }

        return {
            getNccrPageInfo,
        }
    }
}
</script>
